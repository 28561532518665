$(function() {


   /* $('.hero__btn, .section5__btn, .section7__el-btn').on('click',function(e){
        e.preventDefault();
        $('#singing-modal').arcticmodal({afterClose: function(data, el){$('body').removeAttr('style');}});
    });

    $('.section11__btn').on('click',function(e){
        e.preventDefault();
        $('#call-modal').arcticmodal({afterClose: function(data, el){$('body').removeAttr('style');}});
    });

    /!*$('.modal-form__btn, .section8__form-btn, .section9__form-btn').on('click',function(e){
        e.preventDefault();
        $.arcticmodal('close');
        $('#ty-modal').arcticmodal({afterClose: function(data, el){$('body').removeAttr('style');}});
    });*!/

    $('.modal-form__text a, .section8__form-info a, .section9__form-text a').on('click',function(e){
        e.preventDefault();
        $.arcticmodal('close');
        $('#pol-modal').arcticmodal({afterClose: function(data, el){$('body').removeAttr('style');}});
    });*/
});