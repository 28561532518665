$(function() {

    $('.section2__head a').on('click', function(e){
       e.preventDefault();

        $('.section2__head a').removeClass('active');
        $(this).addClass('active');

        $('.section2__body>div').removeClass('active');
        $('.section2__body #'+$(this).data('go')).addClass('active');

    });

    $('.section2__el-btn').on('click',function(e){
        e.preventDefault();

        $('.specials-over').addClass('active');
        $('.specials-modal').addClass('active');
        $('body').css('overflow','hidden');
    });

    $('.specials-page .specials-modal__close').on('click',function(e){
        e.preventDefault();

        $('.specials-over').removeClass('active');
        $('.specials-modal').removeClass('active');
        $('body').removeAttr('style');
    });

    $('.specials-over').on('click',function(e){
        e.preventDefault();

        $('.specials-over').removeClass('active');
        $('.specials-modal').removeClass('active');
        $('body').removeAttr('style');
    });

});