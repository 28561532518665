$(function() {

    $('.section4__slider').slick({
        dots: false,
        infinite: true,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        adaptiveHeight: true
    });

});