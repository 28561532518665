$(function() {

    $('.header__nav-button').on('click',function(e){
        e.preventDefault();

        $(this).toggleClass('active');


        $('.header__search-box').removeClass('active');
        $('.header__search-wp').removeClass('active');

        $('.header__nav').toggleClass('active');


        if($('.header__nav').hasClass('active')){
            $('body').css('overflow','hidden');
        }else{
            $('body').removeAttr('style');
        }
    });

    $('.header__search-icon').on('click',function(e){
        e.preventDefault();

        $('.header__search-box').addClass('active');
        $('.header__search-wp').addClass('active');
    });

    $('.header__search-wp').on('click',function(e){
        e.preventDefault();

        $('.header__search-box').removeClass('active');
        $('.header__search-wp').removeClass('active');
    });

    $('.header__nav-services-head').on('click',function(e){
        e.preventDefault();
        $('.header__nav-services-status').html('развернуть услуги');
        if($(this).closest('.header__nav-services-el').hasClass('active')){
            $('.header__nav-services-el').removeClass('active');
        }else {
            $('.header__nav-services-el').removeClass('active');
            $(this).closest('.header__nav-services-el').addClass('active');
            $(this).closest('.header__nav-services-el').find('.header__nav-services-status').html('свернуть услуги');
        }

    });

/*
    $('.header__mobilenav-btn').on('click',function(e){
        $('.header__mobilenav-box').addClass('active');
    });

    $('.header__mobilenav-close').on('click',function(e){
        $('.header__mobilenav-box').removeClass('active');
    });



    $('.header__nav a, .footer__nav a, .header__mobilenav-box nav a').on('click',function(e){
        e.preventDefault();

        $('.header__mobilenav-box').removeClass('active');

        var target = $(this).data('target');

        $('html, body').animate({
            scrollTop: ($(''+target+'').offset().top - 30)
        }, 1000);
    });*/
}); 

