$(function() {


    $( ".contact-modal__select select" ).selectmenu();

    $('.open-modal-get').on('click',function(e){
        e.preventDefault();

        $('.laser-over').removeClass('active');
        $('.specials-over').removeClass('active');
        $('.laser-modal').removeClass('active');
        $('.specials-modal').removeClass('active');

        $('.contact-over').addClass('active');
        $('.contact-modal').addClass('active');
        $('body').css('overflow','hidden');
    });

    $('.contact-modal__close').on('click',function(e){
        e.preventDefault();

        $('.contact-over').removeClass('active');
        $('.contact-modal').removeClass('active');
        $('body').removeAttr('style');
    });

    $('.contact-over').on('click',function(e){
        e.preventDefault();

        $('.contact-over').removeClass('active');
        $('.contact-modal').removeClass('active');
        $('body').removeAttr('style');
    });

    $('.contact-modal__btn').on('click',function (e) {
       e.preventDefault();

        $('.contact-modal').addClass('ok');
    });
});