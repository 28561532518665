$(function() {


    $('.laser-page .section3__slider').slick({
        dots: true,
        infinite: true,
        arrows: true,
        slidesToScroll: 3,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1025,
                settings: {

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                }
            }
        ]
    });


    $('.laser-page .section1__watch span, .laser-page .section1__watch img').on('click',function(e){
        e.preventDefault();
        $.arcticmodal('close');
        $('#video-modal').arcticmodal({afterClose: function(data, el){$('body').removeAttr('style');}});
    });


    $('.laser-page .section5__video').on('click',function(e){
        e.preventDefault();

        $(this).addClass('active');
    });


    $('.laser-page .section2__more').on('click',function(e){
        e.preventDefault();

        $('.laser-over').addClass('active');
        $('.laser-modal').addClass('active');
        $('body').css('overflow','hidden');
    });

    $('.laser-page .laser-modal__close').on('click',function(e){
        e.preventDefault();

        $('.laser-over').removeClass('active');
        $('.laser-modal').removeClass('active');
        $('body').removeAttr('style');
    });

    $('.laser-over').on('click',function(e){
        e.preventDefault();

        $('.laser-over').removeClass('active');
        $('.laser-modal').removeClass('active');
        $('body').removeAttr('style');
    });


    $('.laser-page .section5__add').on('click',function(e){
        e.preventDefault();

        $('.reivew-over').addClass('active');
        $('.reivew-modal').addClass('active');
        $('body').css('overflow','hidden');
    });

    $('.laser-page .reivew-modal__close').on('click',function(e){
        e.preventDefault();

        $('.reivew-over').removeClass('active');
        $('.reivew-modal').removeClass('active');
        $('body').removeAttr('style');
    });

    $('.reivew-over').on('click',function(e){
        e.preventDefault();

        $('.reivew-over').removeClass('active');
        $('.reivew-modal').removeClass('active');
        $('body').removeAttr('style');
    });



    $('.reivew-modal__btn').on('click',function (e) {
        e.preventDefault();

        $('.reivew-modal').addClass('ok');
    });

    if($('main').hasClass('laser-page')) {
        var sectionOffset = $('.section1__bottomnav').offset().top;

        $(window).on('scroll', function () {
            if ($(window).scrollTop() > sectionOffset) {
                $('.laser-page .section1').addClass('scrollnav');
            } else {
                $('.laser-page .section1').removeClass('scrollnav');
            }
        });
    }



    // Cache selectors
    var lastId,
        menuBottom = $(".section1__bottomnav"),
        menuBottomHeight = menuBottom.outerHeight()+15,
        // All list items
        menuItems = menuBottom.find("a"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
        });

// Bind click handler to menu items
// so we can get a fancy scroll animation
    menuItems.click(function(e){
        var href = $(this).attr("href"),
            offsetTop = href === "#" ? 0 : $(href).offset().top-menuBottomHeight+1;
        $('html, body').stop().animate({
            scrollTop: offsetTop
        }, 300);
        e.preventDefault();
    });

    if($('main').hasClass('laser-page')) {
        $(window).scroll(function () {
            var fromTop = $(this).scrollTop()+menuBottomHeight;

            // Get id of current scroll item
            var cur = scrollItems.map(function(){
                if ($(this).offset().top < fromTop)
                    return this;
            });
            // Get the id of the current element
            cur = cur[cur.length-1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                console.log(id);
                menuItems
                    .removeClass("active")
                    .filter("[href='#"+id+"']").addClass("active");
            }
        });
    }
});