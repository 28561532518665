$(function() {
 /*   $('.main-page .section1__silder').slick({
        dots: true,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        adaptiveHeight: true
    });*/

     if($('main').hasClass('main-page')) {
        /* if ($(window).scrollTop() > 0) {
             $('.section1').addClass('goTop');
         }

         $(window).bind('mousewheel DOMMouseScroll MozMousePixelScroll', function (event) {
             delta = parseInt(event.originalEvent.wheelDelta || -event.originalEvent.detail);
             if (delta >= 0) {
                 if ($(window).scrollTop() == 0) {
                     $('.section1').removeClass('goTop');
                 }
             } else {
                 if ($('.section1').hasClass('goTop')) {

                 } else {
                     if ($(window).scrollTop() == 0) {
                         event.preventDefault();
                         $('.section1').addClass('goTop');
                         $('.section2 .section2__title, .section2 .section2__text, .section2 .section2__btn').addClass('animatescroll');

                         setTimeout(function (e){
                             $('.section2 .section2__title, .section2 .section2__text, .section2 .section2__btn').removeClass('animatescroll');
                         },3000);
                     }
                 }
             }
         });
*/

         $(window).on('scroll', function() {
             var ratio = $(window).height();
             var ratioTransform = 1.3 - $(window).scrollTop() / (ratio * 5);

             ratioTransform = ratioTransform < 1 ? 1 : ratioTransform;

             $('.main-page .section2__bg').css({
                 transform: 'scale(' + ratioTransform + ',' + ratioTransform + ')'
             })
         });
     }



    $('.main-page .section1__timeline').addClass('goAnim');
     var swipeAutoplay = 5000;

    if($('main').hasClass('main-page')){
        var slider = new Swiper ('.main-page .section1__silder', {
            speed: 1000,
            autoHeight: true,
            grabCursor: true,
            autoplay: {
                delay: swipeAutoplay,
            },
            navigation: {
                nextEl: '.section1__next',
                prevEl: '.section1__prev',
            },
            on: {
                slideChange: function () {
                    $('.section1__timeline:not(.stopAnum)').removeClass('goAnim');
                },
                slideChangeTransitionEnd: function () {
                    $('.section1__timeline:not(.stopAnum)').addClass('goAnim');
                },
                touchStart: function () {
                    $('.section1__timeline').addClass('stopAnum');
                }
            },
            loop: true,
            parallax: true,
            longSwipesRatio: 0.2
        });
    }


    $('.main-page .section1__next, .main-page .section1__prev').on('click', function(e){
        $('.section1__timeline').addClass('stopAnum');
    });

    $('.main-page .section3__slider').slick({
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    variableWidth: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false
                }
            }
        ]
    });

    $('.section-specials__slider').slick({
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
    });
/*
    var newsEls = $('.main-page .section5__box').html();

    $('.main-page .section5__btn').on('click',function(e){
        e.preventDefault();

        $('.main-page .section5__box').append(newsEls);
    });*/
});